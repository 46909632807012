import PropTypes from 'prop-types'
import { useAtomValue } from 'jotai'
import { rundownAtom } from '../../store/rundown.store.js'
import { runnerAtom, runnerStateAtom, ONAIR } from '../../store/runner.store.js'
import { memo } from 'react'

function CueItemIndex ({
  cueId,
  className,
  index,
  readonly,
  loading,
  onSetNext,
  onJumpToCue,
}) {
  // Note: We avoid using the timestamp to avoid frequent re-renders
  const rundown = useAtomValue(rundownAtom)
  const runner = useAtomValue(runnerAtom)
  const runnerState = useAtomValue(runnerStateAtom)

  const isActive = runner?.timesnap?.cueId === cueId
  const nextCueId = runnerState === ONAIR ? runner?.nextCueId : rundown.startCueId
  const isNext = nextCueId === cueId

  return (
    <button
      className={[
        'h-full w-full font-mono border-2 border-transparent rounded transition-color focus:outline-none focus:ring',
        (isNext && 'border-white animate-pulse'),
        (loading && 'bg-animated from-transparent to-white/20'),
        (!(readonly || isNext || isActive) && 'hover:border-white/50'),
        className,
      ].join(' ')}
      onClick={onSetNext}
      onDoubleClick={onJumpToCue}
      disabled={readonly || isActive}
    >
      <span>{index}</span>
    </button>
  )
}

CueItemIndex.propTypes = {
  cueId: PropTypes.string,
  className: PropTypes.string,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  readonly: PropTypes.bool,
  loading: PropTypes.bool,
  onSetNext: PropTypes.func,
  onJumpToCue: PropTypes.func,
}

export default memo(CueItemIndex)
