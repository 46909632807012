import { useAtomValue } from 'jotai'
import { timestampsAtom } from '../../../store/timestamps.store'
import { addMilliseconds } from 'date-fns'
import { formatDurationHuman } from '../../../utils/formatTime'
import { rundownAtom } from '../../../store/rundown.store'
import { runnerAtom } from '../../../store/runner.store'
import { applyDate, moveAfterWithTolerance } from '@rundown-studio/timeutils'
import { CUE_OVERLAP_TOLERANCE } from '@rundown-studio/consts'

/**
 * OverUnder Component
 *
 * Displays the "over/under" status for a rundown, comparing the actual end time
 * with the planned or original end time. Shows whether the rundown is early,
 * on time, or late.
 *
 * Features:
 * - Calculates the timing difference dynamically.
 * - Formats the difference as "early," "on time," or "late".
 */
export default function OverUnder () {
  const timestamps = useAtomValue(timestampsAtom)
  const rundown = useAtomValue(rundownAtom)
  const runner = useAtomValue(runnerAtom)

  if (!timestamps) return null

  const actualEnd = addMilliseconds(timestamps.actual.start, timestamps.actual.duration)
  const originalEnd = addMilliseconds(timestamps.original.start, timestamps.original.duration)

  // Move to after actual end, moving rundown end time to today (like timestamps logic: everything moves to today) and use tolerance to allow being late.
  const plannedEnd = moveAfterWithTolerance(applyDate(rundown.endTime, new Date(), { timezone: rundown.timezone }), actualEnd, CUE_OVERLAP_TOLERANCE, { timezone: rundown.timezone })

  // Depending if the rundown is running or being edited, it compares against the
  // original end (last cue original end) or the planned rundown end.
  const compareTo = runner ? originalEnd : plannedEnd

  const diff = compareTo - actualEnd

  return (
    <section>
      <div className={['text-gray-500 font-semibold whitespace-pre text-[10px]'].join(' ')}>
        <p>{ runner ? 'Over / Under' : 'Rundown ends' }</p>
      </div>
      <span className="text-sm leading-6">
        {diff >= 1000
          ? (
              <p className="text-green-500">
                <span className="font-mono">{formatDurationHuman(diff)}</span>
                {' '}
                early
              </p>
            )
          : ''}
        {Math.abs(diff) < 1000 ? (runner ? '--' : 'On time') : ''}
        {diff <= -1000
          ? (
              <p className="text-red-500">
                <span className="font-mono">{formatDurationHuman(diff)}</span>
                {' '}
                late
              </p>
            )
          : ''}
      </span>
    </section>
  )
}
