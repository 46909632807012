import PropTypes from 'prop-types'
import { memo } from 'react'
import { useAtomValue } from 'jotai'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getTimestampByCueIdAtom } from '../../../store/timestamps.store.js'
import { getPreviousCueAtom } from '../../../store/rundown.store.js'
import { useTimingInfo } from './useTimingInfo.js'

function TimingIndicator ({ cue, timezone = 'UTC' }) {
  const getTimestampByCueId = useAtomValue(getTimestampByCueIdAtom)
  const getPreviousCueById = useAtomValue(getPreviousCueAtom)

  const timestamp = getTimestampByCueId(cue.id)
  const previousCueTimestamp = getTimestampByCueId(getPreviousCueById(cue.id)?.id)

  if (!timestamp || !previousCueTimestamp) return null

  const { text, textColor, bgColor, icon } = useTimingInfo(cue, previousCueTimestamp, timestamp, timezone)

  return (
    <div className="flex items-center gap-2 leading-5 text-xs text-gray-500">
      <span className={[textColor, 'relative flex h-3 w-3'].join(' ')}>
        <span className={['animate-ping absolute inline-flex h-full w-full rounded-full opacity-50', bgColor].join(' ')}></span>
        {icon && <FontAwesomeIcon icon={icon} />}
      </span>
      <span>{text}</span>
    </div>
  )
}

TimingIndicator.propTypes = {
  cue: PropTypes.object.isRequired,
  timezone: PropTypes.string,
}

export default memo(TimingIndicator)
