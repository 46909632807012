import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Button from '../components/Button'
import Input from '../components/Input'
import Layout from '../components/Layout'
import { getUser, getUserTeams } from '../firebase'
import { createRundown } from '../firestore'
import TimezoneSelect from '../components/interactives/TimezoneSelect.jsx'
import Select from '../components/Select.jsx'
import { addDays } from '@rundown-studio/timeutils'

const browserTz = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone

export default function Create() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState(null)
  const [teams, setTeams] = useState([])

  const [config, setConfig] = useState({
    name: '',
    timezone: browserTz,
    teamId: localStorage.getItem('teamId') || '',
    eventId: null,
  })

  function patchConfig (key, value) {
    setConfig({
      ...config,
      [key]: value,
    })
  }

  async function handleCreateRundown (event) {
    event.preventDefault()

    setLoading(true)

    const startTime = addDays(new Date(), 1, { timezone: browserTz })
    startTime.setHours(9, 0, 0, 0)

    const { data } = await createRundown({
      name: config.name || 'New rundown',
      teamId: config.teamId,
      timezone: config.timezone,
      startTime,
      eventId: config.eventId,
    })

    return navigate(`/rundown/${data?.id}`)
  }

  async function getUserAndTeams () {
    setUser(await getUser())
    setTeams(await getUserTeams())
  }

  useEffect(() => {
    getUserAndTeams()
  }, [])

  useEffect(() => {
    patchConfig('eventId', searchParams.get('event'))
  }, [searchParams])

  return (
    <Layout title="Create a new rundown">
      <form
        className="space-y-4"
        onSubmit={(event) => handleCreateRundown(event)}
      >
        {/* Name */}
        <Input
          label="Name"
          placeholder="My new rundown..."
          autofocus
          required
          value={config.name}
          onChange={(name) => patchConfig('name', name)}
        />
        {/* Hidden input value for DEV purposes */}
        <input value={config.eventId || undefined} className="bg-black h-0 hidden" readOnly />

        {/* Timezone */}
        <div className="group bg-[#161616] border-b-2 border-transparent focus-within:border-blue-500">
          <span className="block px-3 pt-2 text-blue-500 text-xs">
            Timezone
          </span>
          <TimezoneSelect
            className="bg-transparent hover:brightness-100"
            value={config.timezone}
            setValue={(timezone) => patchConfig('timezone', timezone)}
          />
        </div>

        {/* Team */}
        <Select
          label="Team"
          value={config.teamId}
          required={teams?.length > 0}
          disabled={!teams?.length}
          options={[
            {
              name: 'Choose a team...',
              value: '',
            },
            ...teams.map((team) => ({
              name: team.name,
              value: team.id,
            })),
          ]}
          onChange={(teamId) => {
            patchConfig('teamId', teamId)
          }}
        />

        <div className="flex gap-6">
          {user?.uid && (
            <Button
              text="Back"
              colour="dark"
              onClick={() => {
                if (config.eventId) {
                  navigate(`/dashboard/${config.teamId}/event/${config.eventId}`)
                } else {
                  navigate('/dashboard')
                }
              }}
              className="w-full"
              style={{ flexGrow: 1 }}
              type="button"
              loading={loading}
            />
          )}
          <Button
            text={loading ? 'Creating...' : 'Create rundown'}
            className="w-full"
            style={{ flexGrow: 1 }}
            type="submit"
            loading={loading}
          />
        </div>
      </form>
    </Layout>
  )
}
