import PropTypes from 'prop-types'
import { memo, useState, useEffect } from 'react'
import { useAtomValue } from 'jotai'
import { Tooltip, TooltipContent, TooltipTrigger } from '../../interactives/Tooltip.jsx'
import { getTimestampByCueIdAtom } from '../../../store/timestamps.store.js'
import { getPreviousCueAtom, rundownSettingsAtom } from '../../../store/rundown.store.js'
import { formatCountdown } from '../../../utils/formatTime.js'
import { formatTimeOfDay } from '@rundown-studio/timeutils'
import { useTimingInfo } from './useTimingInfo.js'

function FloatingCountdownButton({ cue, timezone = 'UTC', onScrollTo }) {
  const rundownSettings = useAtomValue(rundownSettingsAtom)
  const getTimestampByCueId = useAtomValue(getTimestampByCueIdAtom)
  const getPreviousCueById = useAtomValue(getPreviousCueAtom)
  const [count, setCount] = useState(0)

  const timestamp = getTimestampByCueId(cue.id)
  const previousCueTimestamp = getTimestampByCueId(getPreviousCueById(cue.id)?.id)
  const nextHardCueTimestamp = getTimestampByCueId(cue.id)
  const countdown = nextHardCueTimestamp?.actual.start.getTime() - Date.now()

  const { type, timeDiffStr, textColor } = useTimingInfo(cue, previousCueTimestamp, timestamp, timezone)

  const nextHardStartStr = formatTimeOfDay(nextHardCueTimestamp?.actual.start || 0, {
    timezone,
    seconds: 'nonzero',
    format: rundownSettings.todDisplayFormat,
  })

  useEffect(() => {
    const interval = setInterval(() => setCount(count + 1), 100)
    return () => clearInterval(interval)
  }, [count])

  return (
    <button className="fixed bottom-0 left-10 text-sm z-50 rounded-t border-x border-t border-white/10 bg-black/80 shadow-lg backdrop-blur-lg group" onClick={onScrollTo}>
      <Tooltip placement="right">
        <TooltipTrigger>
          <div className="flex text-left px-4 py-2">
            <section className="min-w-24">
              <div className="text-gray-500 font-semibold whitespace-pre text-[10px]">Next hard start</div>
              <span className="text-sm font-mono">{nextHardStartStr}</span>
            </section>
            <section className="min-w-24">
              <div className="text-gray-500 font-semibold whitespace-pre text-[10px]">Countdown</div>
              <span className={['text-sm font-mono', countdown < 10000 ? 'text-red-700' : ''].join(' ')}>
                {countdown < 0 ? '+' : ''}{formatCountdown(countdown)}
              </span>
            </section>
            <section className="min-w-32">
              <div className="text-gray-500 font-semibold whitespace-pre text-[10px]">{type || 'Status'}</div>
              <span className={['text-sm font-mono', textColor].join(' ')}>{timeDiffStr}</span>
            </section>
            <section className="mr-2">
              <div className="text-gray-500 font-semibold whitespace-pre text-[10px]">Title</div>
              <span className="text-sm">{cue.title}</span>
            </section>
          </div>
        </TooltipTrigger>
        <TooltipContent>Scroll to cue</TooltipContent>
      </Tooltip>
    </button>
  )
}

FloatingCountdownButton.propTypes = {
  cue: PropTypes.object.isRequired,
  timezone: PropTypes.string,
  onScrollTo: PropTypes.func.isRequired,
}

export default memo(FloatingCountdownButton)
