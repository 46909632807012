import { faClock, faWarning } from '@fortawesome/free-solid-svg-icons'
import { addMilliseconds } from 'date-fns'
import { CueStartMode } from '@rundown-studio/types'
import floorMs from '../../../utils/floorMs'
import { formatDurationHuman } from '../../../utils/formatTime'
import { isSameDay } from '@rundown-studio/timeutils'

export function useTimingInfo(cue, previousCueTimestamp, timestamp, timezone) {
  const previousEndTime = addMilliseconds(previousCueTimestamp.actual.start, previousCueTimestamp.actual.duration)
  const differenceMs = floorMs(timestamp.actual.start).getTime() - floorMs(previousEndTime).getTime()
  const timeDiffStr = differenceMs === 0 ? 'On time' : formatDurationHuman(differenceMs)

  let type = ''
  let text = ''
  let textColor = ''
  let bgColor = ''
  let icon = null

  if (cue.startMode === CueStartMode.FIXED) {
    if (differenceMs > 0) {
      type = 'Gap'
      text = `Gap of ${timeDiffStr}`
      textColor = 'text-green-600'
      bgColor = 'bg-green-400'
      icon = faClock
    } else if (differenceMs < 0) {
      type = 'Overlap'
      text = `Overlap by ${timeDiffStr}`
      textColor = 'text-red-600'
      bgColor = 'bg-red-400'
      icon = faWarning
    }
  }

  if (!isSameDay(timestamp.actual.start, previousCueTimestamp.actual.start, { timezone })) {
    if (!text) text = 'Crossing midnight'
    else text = `${text} and crossing midnight`
  }

  return { type, text, textColor, bgColor, icon, timeDiffStr }
}
